<template>
  <div class="lp-card-service-container columns">
    <div class="column is-4">
      <div class="tile">
        <div class="tile is-parent is-vertical">
          <article class="tile is-child">
            <ul class="lp-card-package-title">{{ card.package.title }}</ul>
            <li v-for="(desc, idx) in card.package.desc" :key="'desc-'+idx"
              class="lp-card-package-desc">{{ desc }}</li>
          </article>
        </div>
      </div>
    </div>
    <div class="column is-8">
      <div v-for="(themeCard, idx) in card.themes" :key="'card-'+idx"
           class="tile is-parent">
        <div class="tile lp-card unselect" @click="clickCard(themeCard)">
          <div class="tile is-half is-child relative lp-card-img">
            <div :style="cardImgStyle(themeCard)"></div>
            <div class="lp-card-demo unselect" @click.stop.prevent="">
              <a :href="card.demo" target="_blank">데모보기</a>
            </div>
          </div>
          <div class="tile is-child" style="padding:4px 16px">
            <div class="lp-card-title">{{ themeCard.title }}</div>
            <div class="lp-card-desc">{{ themeCard.desc }}</div>

            <div class="flex" style="margin-bottom: 12px">
              <div class="lp-card-price unselect" @click.stop.prevent="themeCard.priceType=0"
                   :class="{'lp-card-price-select': themeCard.priceType===0}">Start-up 요금제</div>
              <div class="lp-card-price unselect" @click.stop.prevent="themeCard.priceType=1"
                   :class="{'lp-card-price-select': themeCard.priceType===1}">Enterprise 요금제</div>
            </div>

            <div>
              <div class="lp-price-text"><span class="lp-price">{{ themeCard.price[themeCard.priceType].setting|currencyNum }}</span>원 / 초기 {{ card.package.title }} 설치료</div>
              <div class="lp-price-text"><span class="lp-price">{{ themeCard.price[themeCard.priceType].billing|currencyNum }}</span>원 / 월 정기 이용료</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "card-service",

    props: ['card'],

    data() {
      return {

      }
    },

    methods: {
      cardImgStyle(card) {
        let deco = {
          width: '100%',
          height: '228px',
          backgroundImage: `url(${ card.img })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }

        return deco;
      },
      clickCard(card) {
        this.$store.commit('setBasket', card);
        if(this.isLogin)
          this.$router.push('account');
        else
          this.$store.commit('setOpenLogin', true);
      }
    }
  }
</script>

<style scoped>
  .lp-card-service-container {
    margin-bottom: 40px !important;
  }

  .lp-card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 4px;
    padding: 20px;
  }

  .lp-card-package-desc,
  .lp-card-desc,
  .lp-card-price,
  .lp-price-text {
    color: rgb(131, 131, 131);
  }

  .lp-card-package-title,
  .lp-card-title,
  .lp-card-price:hover,
  .lp-price,
  .lp-card-price-select {
    color: rgb(57, 62, 70);
  }

  .lp-card-package-title {
    font-size: 1.2rem;
    margin-bottom: 12px;
    font-weight: 600;
  }

  .lp-card-package-desc,
  .lp-card-desc {
    font-size: 0.95rem;
    margin-bottom: 4px;
  }

  .lp-card-package-desc {
    margin-left: 8px;
  }

  .lp-card-desc {
    height: 80px;
  }

  .lp-card-title {
    font-size: 1.2rem;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .lp-card-price {
    margin: 4px 8px;
    border-bottom: 2px solid transparent;
    font-size: 0.95rem;
  }

  .lp-card-price:first-child {
    margin-left: 0;
  }
  .lp-card-price:last-child {
    margin-right: 0;
  }

  .lp-card-price-select {
    border-bottom: 2px solid rgb(255, 102, 0);
  }

  .lp-card-demo {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
  }

  .lp-card-demo a {
    border: 1px solid rgb(255, 102, 0);
    border-radius: 3px;
    width: 80px;
    height: 40px;
    color: white;
    font-size: 0.9rem;
    text-align: center;
    line-height: 40px;
  }

  .lp-card-demo a:hover {
    background-color: rgb(255, 102, 0);
  }

  .lp-card-img:hover .lp-card-demo {
    display: flex;
  }

  .lp-card:hover .lp-card-title {
    color: rgb(255, 102, 0);
  }

  .lp-price-text {
    font-size: 0.9rem;
  }

  .lp-price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 4px;
  }



  @media(min-width:1024px){
    .col-wrap {
      width: 1080px;
    }

  }

</style>
